/* eslint-disable jsx-a11y/alt-text */
import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(() => ({
  root: {
    left: 0,
    display: "block",
    width: "100vw",
  },
}))

const WideImage = ({ ...props }) => {
  const classes = useStyles()

  return <GatsbyImage loading="eager" {...props} className={classes.root} />
}

export default WideImage
