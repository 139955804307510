import React, { useState } from "react"
import PropTypes from "prop-types"

import Grid from "@material-ui/core/Grid"

import SectionTitle from "../SectionTitle"
import BaseCarousel from "./BaseCarousel"
import TestimonialThumbCard from "../cards/TestimonialThumbCard"

const queryString = require('query-string');

const TestimonialsCarousel = ({ testimonials, slidesToShow }) => {
  const [currentTestimonial, setCurrentTestimonial] = useState(
    testimonials[0].uid
  )

  const onSelectTestimonial = selected => {
    setCurrentTestimonial(selected)
  }

  return (
    <>
      <Grid container>
        {testimonials.map(
          testimonial =>
            testimonial.uid === currentTestimonial && (
              <Grid item xs={12} key={testimonial.uid}>
                <iframe
                  title={testimonial.data.video.title}
                  src={`https://www.youtube.com/embed/${queryString.parse(
                    testimonial.data.video.embed_url.replace(
                      "https://www.youtube.com/watch?",
                      ""
                    )
                  ).v
                    }?feature=oembed`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </Grid>
            )
        )}
      </Grid>

      <br />

      <SectionTitle component="h2">
        Experiențele pacienților cu{" "}
        <span style={{ fontWeight: 700 }}>MONZA ARES HELP</span>
      </SectionTitle>

      <br />

      <BaseCarousel
        slidesToShow={slidesToShow}
        elements={testimonials}
        render={testimonial => (
          <TestimonialThumbCard
            testimonial={testimonial}
            onSelect={onSelectTestimonial}
            textMaxLength={1000}
          />
        )}
      />
    </>
  )
}

export default TestimonialsCarousel

TestimonialsCarousel.defaultProps = {
  slidesToShow: 3,
}

TestimonialsCarousel.propTypes = {
  testimonials: PropTypes.array.isRequired,
  slidesToShow: PropTypes.number,
}
