import React, { useState } from "react"
import PropTypes from "prop-types"

import shortid from "shortid"

import { graphql } from "gatsby"

import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

import SEO from "../components/SEO"
import Breadcrumbs from "../components/Breadcrumbs"
import SectionTitle from "../components/SectionTitle"
import WideImage from "../components/WideImage"

import SimpleInfoCard from "../components/cards/SimpleInfoCard"
import HelperDetailCard from "../components/cards/HelperDetailCard"
import TestimonialsCarousel from "../components/carousels/TestimonialsCarousel"
import ContactPopup from "../components/contacts/ContactPopup"

import logo from "../img/logo_monza_ares_help_blue.png"

const HelpersPage = ({
  data: { helpersPage, helpers },
  pageContext: { locale, breadcrumbs },
  location,
}) => {
  const schema = helpersPage.data.schema_json.text || ""
  const ogp = helpersPage.data.ogp_tags || ""
  const meta = {
    title: helpersPage.data.meta_title,
    description: helpersPage.data.meta_description,
    keywords: helpersPage.data.meta_keywords,
    author: "Medicina Interventionala",
  }

  const [selectedHelperUid, setSelectedHelperUid] = useState("-")
  const [contactModalOpen, setContactModalOpen] = useState(false)

  const onSelectHelper = helperUid => {
    setSelectedHelperUid(helperUid)
    setContactModalOpen(true)
  }

  const helperList = helpers.edges.map(r => r.node)

  const testimonials = helpersPage.data.testimonials.map(
    ({ testimonial }) => testimonial.document
  )

  return (
    <>
      <SEO
        schema={schema}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
      />

      <main>
        <Container maxWidth="lg">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <br />
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={4}>
                  <img
                    src={logo}
                    alt="MONZA ARES"
                    className="logoWhiteNoBg"
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <br />

        <WideImage
          image={helpersPage.data.image.gatsbyImageData}
          alt={helpersPage.data.title}
        />
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography
                component="div"
                dangerouslySetInnerHTML={{
                  __html: helpersPage.data.intro.html,
                }}
              />
              <SectionTitle component="h2">
                <span style={{ fontWeight: 700 }}>Ce este MONZA ARES HELP?</span>
              </SectionTitle>
              <Typography
                component="div"
                dangerouslySetInnerHTML={{
                  __html: helpersPage.data.about.html,
                }}
              />
              <br />

              <SectionTitle component="h2">
                <span style={{ fontWeight: 700 }}>
                  Cum funcționează MONZA ARES HELP?
                </span>
              </SectionTitle>
              <Typography
                component="div"
                dangerouslySetInnerHTML={{
                  __html: helpersPage.data.how_it_works.html,
                }}
              />
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                {helpersPage.data.info_boxes.map(infoBox => (
                  <Grid item xs={12} sm={6} md={4} key={shortid.generate()}>
                    <SimpleInfoCard
                      image={infoBox.image.gatsbyImageData}
                      text={infoBox.description.text}
                    />
                  </Grid>
                ))}
              </Grid>
              <br />
              <SectionTitle component="h2">
                <span style={{ fontWeight: 700 }}>
                  Alege MONZA ARES HELPER-ul tău
                </span>
              </SectionTitle>
              <br />
              <Grid container spacing={2}>
                {helperList.map(helper => (
                  <Grid item xs={12} sm={6} md={4} key={helper.uid}>
                    <HelperDetailCard
                      helper={helper}
                      onSelect={onSelectHelper}
                    />
                  </Grid>
                ))}
              </Grid>

              <br />

              <TestimonialsCarousel testimonials={testimonials} />
            </Grid>
          </Grid>
        </Container>
        <ContactPopup
          locale={locale}
          location={location}
          open={contactModalOpen}
          handleClose={() => setContactModalOpen(false)}
          selectedHelperUid={selectedHelperUid}
        />
      </main>
    </>
  )
}

export default HelpersPage

export const query = graphql`
  query HelpersPageQuery($slug: String!, $locale: String!) {
    settings: prismicSettings(lang: { eq: $locale }) {
      data {
        site_title
      }
    }

    helpersPage: prismicHelpersPage(uid: { eq: $slug }, lang: { eq: $locale }) {
      first_publication_date
      last_publication_date
      data {
        title
        intro {
          html
        }
        about {
          html
        }
        how_it_works {
          html
        }
        info_boxes {
          description {
            text
          }
          image {
            alt
            copyright
            url
            gatsbyImageData(layout: FIXED, width: 100)
          }
        }
        image {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        testimonials {
          testimonial {
            document {
              ... on PrismicTestimonial {
                uid
                data {
                  description {
                    text
                  }
                  title {
                    text
                  }
                  video {
                    embed_url
                    thumbnail_url
                    title
                  }
                }
              }
            }
          }
          short_text
          video {
            embed_url
            title
          }
        }
        meta_description
        meta_keywords
        meta_title
        schema_json {
          text
        }
        ogp_tags {
          content
          property
        }
      }
    }

    helpers: allPrismicHelper(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          lang
          uid
          data {
            name {
              text
            }
            description {
              html
            }
            ratings {
              field
              value
            }
            specialisations {
              specialisation: specialization {
                document {
                  ... on PrismicSpecialisation {
                    id
                    uid
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            profile_image {
              alt
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 1.5
              )
            }
          }
        }
      }
    }
  }
`

HelpersPage.propTypes = {
  data: PropTypes.shape({
    settings: PropTypes.object.isRequired,
    helpersPage: PropTypes.object.isRequired,
    helpers: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
