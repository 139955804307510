import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"

import makeStyles from "@material-ui/core/styles/makeStyles"

import Card from "@material-ui/core/Card"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(() => ({
  card: {
    border: "1px solid #b4b5b8",
    height: 210,
  },

  cardInner: {
    height: "100%",
  },

  image: {
    height: 94,
  },

  text: {
    color: "#065ea6",
    textAlign: "center",
    padding: "0 25px",
    fontSize: 16,
  },
}))

const SimpleInfoCard = ({ image, text }) => {
  const classes = useStyles()

  return (
    <Card elevation={0} square className={classes.card}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.cardInner}
      >
        <Grid item>
          <GatsbyImage
            className={classes.image}
            image={image}
            alt={text ?? ""}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.text}>{text}</Typography>
        </Grid>
      </Grid>
    </Card>
  )
}

export default SimpleInfoCard

SimpleInfoCard.propTypes = {
  image: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
}
